import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const AIAssistantShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-blue-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left side: Mock-up */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 mb-10 lg:mb-0"
          >
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="bg-gray-200 px-4 py-2 flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <span className="text-sm text-gray-700 font-medium">AI assistant</span>
              </div>
              <div className="p-6">
                <div className="bg-blue-50 rounded p-4 mb-4">
                  <h3 className="font-semibold text-blue-700 mb-2">Platform expansion strategy</h3>
                  <p className="text-sm text-gray-700">
                    This document outlines Acme's platform expansion strategy in the gaming industry. It emphasizes the importance of assessing current platforms, conducting market research, and establishing selective criteria. The strategy includes partnership development and optimization efforts, user acquisition and marketing strategies, monitoring and evaluation, and a future expansion roadmap to stay ahead in the dynamic gaming landscape.
                  </p>
                </div>
                <div className="flex space-x-2 mb-4">
                  <button className="px-4 py-2 bg-blue-100 text-blue-700 rounded-full text-sm font-medium">Ask a follow-up...</button>
                  <button className="px-4 py-2 bg-blue-100 text-blue-700 rounded-full text-sm font-medium">How will we prioritize where to expand?</button>
                </div>
                <div className="bg-gray-100 p-4 rounded">
                  <h4 className="font-semibold mb-2 text-gray-800">Platform expansion strategy</h4>
                  <p className="text-sm text-gray-700">Introduction</p>
                  <p className="text-sm text-gray-700">In the rapidly evolving landscape of the gaming industry, it is crucial for [Company Name] to develop a comprehensive platform expansion strategy to ensure sustained growth...</p>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right side: Text content */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2 lg:pl-16"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Find answers & generate content you can trust.</h2>
            <p className="text-xl mb-8 text-gray-700">
              Argus AI assistant helps you quickly find information and generate reliable content based on your organization's data.
            </p>
            <Link to='/features/ai-assistant'>
              <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300">
                Explore Argus Assistant
              </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AIAssistantShowcase;