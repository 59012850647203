import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, Database, Globe, Clock, Bell, Users } from 'lucide-react';
import './PrivacyPolicyPage.css';

const lastUpdated = "November 1, 2024";

const sections = [
    {
        icon: Shield,
        title: "Information Collection",
        content: `We collect information that you provide directly to us, including:

• Name, email address, and contact information
• Company name and job title
• Usage data and interaction with our services
• Information about your system and browser
• Log data and device information

This information is collected when you:
• Create an account or sign up for our services
• Use our product features and functionalities
• Contact our support team
• Subscribe to our newsletters
• Participate in surveys or feedback sessions`
    },
    {
        icon: Database,
        title: "Use of Information",
        content: `We use the collected information to:

• Provide, maintain, and improve our services
• Process your transactions and manage your account
• Send you technical notices and support messages
• Communicate with you about products, services, and events
• Monitor and analyze trends and usage
• Detect, investigate, and prevent fraudulent transactions
• Develop new products and services
• Personalize your experience and content
• Comply with legal obligations`
    },
    {
        icon: Globe,
        title: "Information Sharing",
        content: `We may share your information with:

• Service providers and business partners
• Professional advisors and consultants
• Law enforcement when required by law
• Other parties in connection with a business transfer

We do not sell your personal information to third parties. Any sharing of information is done with appropriate safeguards and data protection agreements in place.`
    },
    {
        icon: Lock,
        title: "Data Security",
        content: `We implement appropriate security measures including:

• End-to-end encryption for data transmission
• Secure data storage with encryption at rest
• Regular security audits and assessments
• Access controls and authentication
• Monitoring for suspicious activities
• Regular security training for employees
• Incident response procedures

We follow industry best practices to protect your information from unauthorized access, disclosure, alteration, and destruction.`
    },
    {
        icon: Eye,
        title: "Your Privacy Rights",
        content: `You have the right to:

• Access your personal information
• Correct inaccurate data
• Request deletion of your data
• Opt-out of marketing communications
• Restrict processing of your data
• Data portability
• Lodge a complaint with supervisory authorities

To exercise these rights, contact us through our designated privacy channels.`
    },
    {
        icon: Clock,
        title: "Data Retention",
        content: `We retain your information for as long as:

• Your account is active
• Needed to provide our services
• Required by law
• Necessary for legitimate business purposes

When data is no longer needed, it is securely deleted or anonymized according to our data retention policies.`
    },
    {
        icon: Bell,
        title: "Updates to Policy",
        content: `We may update this Privacy Policy from time to time. We will notify you of any changes by:

• Posting the new Privacy Policy on our website
• Sending an email to registered users
• Displaying a notice in our application

Your continued use of our services after such modifications constitutes acceptance of the updated Privacy Policy.`
    },
];

const PrivacyPolicyPage = () => {
    return (
        <div className="privacy-policy-page">
            {/* Hero Section */}
            <motion.div 
                className="privacy-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Privacy Policy
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    Your privacy is important to us. This policy outlines how we collect, use, and protect your information.
                </motion.p>
                <motion.div
                    className="last-updated"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    Last Updated: {lastUpdated}
                </motion.div>
            </motion.div>

            {/* Quick Nav */}
            <div className="quick-nav">
                <div className="container">
                    <div className="quick-nav-content">
                        {sections.map((section, index) => (
                            <a 
                                key={index} 
                                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                                className="quick-nav-item"
                            >
                                {section.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            {/* Content Sections */}
            <div className="privacy-content">
                <div className="container">
                    {sections.map((section, index) => (
                        <motion.section
                            key={index}
                            id={section.title.toLowerCase().replace(/\s+/g, '-')}
                            className="privacy-section"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.1 }}
                        >
                            <div className="section-header">
                                <section.icon className="section-icon" />
                                <h2>{section.title}</h2>
                            </div>
                            <div className="section-content">
                                {section.content.split('\n\n').map((paragraph, i) => (
                                    <p key={i}>{paragraph}</p>
                                ))}
                            </div>
                        </motion.section>
                    ))}
                </div>
            </div>

            {/* Contact Section */}
            <div className="privacy-contact">
                <div className="container">
                    <motion.div
                        className="contact-card"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Have Questions?</h2>
                        <p>Our privacy team is here to help you understand how we protect your data.</p>
                        <a href="/contact" className="contact-button">
                            Contact Privacy Team
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;