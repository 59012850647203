import React from 'react';
import { motion } from 'framer-motion';
import { Scale, FileText, Shield, AlertCircle, UserCheck, CreditCard, Lock, Ban, HelpCircle } from 'lucide-react';
import './TermsOfServicePage.css';

const lastUpdated = "November 1, 2024";

const sections = [
    {
        icon: UserCheck,
        title: "Acceptance of Terms",
        content: `By accessing or using ARGUS's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.

These terms apply to all users, including without limitation: browsers, customers, merchants, vendors and content contributors.

We reserve the right to update or modify these terms at any time without prior notice. Your continued use of our services following any changes constitutes acceptance of those changes.`
    },
    {
        icon: FileText,
        title: "Service Description",
        content: `ARGUS provides an AI-powered knowledge management platform that includes:

• Document management and organization
• AI-powered search capabilities
• Advanced analytics and reporting
• Integration with existing systems
• Custom deployment options

We reserve the right to:
• Modify or withdraw any part of our services
• Limit or deny access to services
• Change service specifications
• Add or remove features

Any new features that augment or enhance the current service shall be subject to these Terms of Service.`
    },
    {
        icon: Shield,
        title: "User Obligations",
        content: `You agree to:

• Provide accurate and complete registration information
• Maintain the security of your account credentials
• Accept responsibility for all activities under your account
• Notify us immediately of any security breaches
• Comply with all applicable laws and regulations

You agree not to:
• License, sell, or transfer your account
• Use the service for illegal purposes
• Upload malicious code or content
• Attempt to gain unauthorized access
• Interfere with or disrupt services
• Engage in automated data collection without permission`
    },
    {
        icon: Lock,
        title: "Data Rights and Usage",
        content: `By using our services, you grant ARGUS the right to:

• Store and process your data
• Display and distribute your content within the service
• Use anonymized data for service improvement
• Maintain backups of your information

You retain all rights to your data and may:
• Access your data at any time
• Export your data in standard formats
• Request deletion of your data
• Control sharing and visibility settings

We will handle your data in accordance with our Privacy Policy and applicable data protection laws.`
    },
    {
        icon: CreditCard,
        title: "Payment Terms",
        content: `For paid services:

• Fees are due in advance of service period
• All fees are non-refundable unless otherwise stated
• We may change fees upon 30 days notice
• Taxes are your responsibility where applicable

Subscription terms:
• Billed on a recurring basis
• Auto-renewal unless cancelled
• Pro-rated refunds not provided
• Payment methods must be kept current

Enterprise customers may have separate payment terms as specified in their service agreements.`
    },
    {
        icon: AlertCircle,
        title: "Warranty Disclaimer",
        content: `THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED.

We do not warrant that:
• The service will be uninterrupted
• The results will be accurate or reliable
• The quality will meet your expectations
• Errors will be corrected

You assume all risk for any damage that may result from your use of or access to the services.`
    },
    {
        icon: Ban,
        title: "Limitation of Liability",
        content: `IN NO EVENT SHALL ARGUS BE LIABLE FOR ANY:

• Indirect or consequential damages
• Lost profits or data
• Business interruption
• Personal injury
• Property damage

Our total liability shall be limited to:
• The amount paid for the service
• Or $500, whichever is greater

Some jurisdictions do not allow the exclusion of certain warranties or limitations, so these limitations may not apply to you.`
    },
    {
        icon: Scale,
        title: "Dispute Resolution",
        content: `Any dispute arising from these terms shall be resolved through:

1. Informal Negotiation:
• Parties will attempt to resolve disputes informally
• Minimum 30-day negotiation period

2. Mediation:
• If negotiation fails, parties will use mediation
• Costs shared equally between parties

3. Arbitration:
• Final binding arbitration if mediation fails
• Conducted in San Francisco, California
• Governed by California law

Class action waiver applies to all disputes.`
    },
];

const TermsOfServicePage = () => {
    return (
        <div className="terms-page">
            {/* Hero Section */}
            <motion.div 
                className="terms-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Terms of Service
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    Please read these terms carefully before using our services.
                </motion.p>
                <motion.div
                    className="last-updated"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    Last Updated: {lastUpdated}
                </motion.div>
            </motion.div>

            {/* Quick Nav */}
            <div className="quick-nav">
                <div className="container">
                    <div className="quick-nav-content">
                        {sections.map((section, index) => (
                            <a 
                                key={index} 
                                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                                className="quick-nav-item"
                            >
                                {section.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            {/* Content Sections */}
            <div className="terms-content">
                <div className="container">
                    {sections.map((section, index) => (
                        <motion.section
                            key={index}
                            id={section.title.toLowerCase().replace(/\s+/g, '-')}
                            className="terms-section"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.1 }}
                        >
                            <div className="section-header">
                                <section.icon className="section-icon" />
                                <h2>{section.title}</h2>
                            </div>
                            <div className="section-content">
                                {section.content.split('\n\n').map((paragraph, i) => (
                                    <p key={i}>{paragraph}</p>
                                ))}
                            </div>
                        </motion.section>
                    ))}
                </div>
            </div>

            {/* Contact Section */}
            <div className="terms-contact">
                <div className="container">
                    <motion.div
                        className="contact-card"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Need Clarification?</h2>
                        <p>Our legal team is available to help you understand our terms of service.</p>
                        <a href="/contact" className="contact-button">
                            Contact Legal Team
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfServicePage;