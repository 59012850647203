import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ChevronDown, MessageCircle, Brain, Lock, Bot, Key, Zap, HelpCircle } from 'lucide-react';
import './FAQPage.css';

const categories = [
    {
        icon: Brain,
        name: "General",
        questions: [
            {
                q: "What is ARGUS?",
                a: "ARGUS is an AI-powered knowledge management platform that helps organizations better organize, search, and utilize their information. Our platform combines advanced search capabilities, document management, and AI assistance to transform how companies handle their knowledge assets."
            },
            {
                q: "How does ARGUS work?",
                a: "ARGUS uses advanced AI to process and understand your organization's documents and data. It creates a searchable knowledge base that can be queried using natural language. The system provides context-aware responses, always citing sources and maintaining security protocols."
            },
            {
                q: "Who is ARGUS for?",
                a: "ARGUS is designed for organizations of all sizes that need to better manage their knowledge assets. This includes engineering firms, professional services companies, research institutions, and any organization dealing with large volumes of documents and data."
            }
        ]
    },
    {
        icon: Lock,
        name: "Security & Privacy",
        questions: [
            {
                q: "How does ARGUS protect our data?",
                a: "ARGUS employs military-grade encryption (AES-256) for data at rest and TLS 1.2+ for data in transit. We maintain strict access controls, regular security audits, and comply with major security standards. Your data is never used to train public AI models."
            },
            {
                q: "Where is our data stored?",
                a: "Your data is stored in secure, enterprise-grade data centers with redundancy and backup systems. You can choose your preferred region for data storage to comply with data residency requirements."
            },
            {
                q: "Who can access our information?",
                a: "Access to your data is strictly controlled through role-based access control (RBAC). Only authorized users within your organization can access your data. ARGUS administrators cannot access your content without explicit permission."
            }
        ]
    },
    {
        icon: Bot,
        name: "AI Features",
        questions: [
            {
                q: "How accurate is the AI?",
                a: "ARGUS's AI is trained on enterprise use cases and maintains high accuracy through source grounding and citation. Every response is backed by your organization's documents, ensuring reliability. The system also indicates confidence levels and provides links to source materials."
            },
            {
                q: "Can ARGUS understand technical documents?",
                a: "Yes, ARGUS is trained to handle complex technical documentation, including engineering specifications, legal documents, and research papers. It can understand industry-specific terminology and maintain context across technical discussions."
            },
            {
                q: "Does ARGUS learn from our usage?",
                a: "ARGUS can be configured to learn from your organization's usage patterns to improve search relevance and response quality. This learning is specific to your instance and never shared across organizations."
            }
        ]
    },
    {
        icon: Key,
        name: "Integration & Setup",
        questions: [
            {
                q: "How long does implementation take?",
                a: "Basic implementation can be completed in a few days. Full enterprise integration, including custom configurations and training, typically takes 2-4 weeks depending on your requirements and data volume."
            },
            {
                q: "What systems can ARGUS integrate with?",
                a: "ARGUS integrates with common enterprise systems including SharePoint, Google Workspace, Microsoft 365, Salesforce, and major CRM systems. We also provide APIs for custom integrations."
            },
            {
                q: "Is training required?",
                a: "While ARGUS is intuitive to use, we provide comprehensive training materials and sessions to help your team get the most out of the platform. Our support team is available 24/7 for assistance."
            }
        ]
    },
    {
        icon: Zap,
        name: "Performance & Capacity",
        questions: [
            {
                q: "How many documents can ARGUS handle?",
                a: "ARGUS is built to scale and can handle millions of documents efficiently. The platform automatically optimizes performance based on your data volume and usage patterns."
            },
            {
                q: "What file types are supported?",
                a: "ARGUS supports all common file types including PDF, Word, Excel, PowerPoint, text files, and images with text. We also support specialized formats through custom connectors."
            },
            {
                q: "What is the system's uptime?",
                a: "ARGUS maintains 99.9% uptime with redundant systems and automatic failover. We provide real-time system status monitoring and notifications."
            }
        ]
    }
];

const FAQPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedItems, setExpandedItems] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('all');

    const toggleExpand = (categoryIndex, questionIndex) => {
        const key = `${categoryIndex}-${questionIndex}`;
        setExpandedItems(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const filteredCategories = categories.filter(category => {
        if (searchQuery === '') return true;
        
        return category.questions.some(q => 
            q.q.toLowerCase().includes(searchQuery.toLowerCase()) ||
            q.a.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    return (
        <div className="faq-page">
            {/* Hero Section */}
            <motion.div 
                className="faq-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Frequently Asked Questions
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="hero-description"
                >
                    Find answers to common questions about ARGUS.
                </motion.p>

                {/* Search Bar */}
                <motion.div 
                    className="search-container"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                >
                    <Search className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search questions..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                </motion.div>
            </motion.div>

            {/* Category Navigation */}
            <div className="category-nav">
                <div className="container">
                    <div className="category-list">
                        <button
                            className={`category-button ${selectedCategory === 'all' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('all')}
                        >
                            <HelpCircle size={20} />
                            All
                        </button>
                        {categories.map((category, index) => (
                            <button
                                key={index}
                                className={`category-button ${selectedCategory === category.name ? 'active' : ''}`}
                                onClick={() => setSelectedCategory(category.name)}
                            >
                                <category.icon size={20} />
                                {category.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* FAQ Content */}
            <div className="faq-content">
                <div className="container">
                    {filteredCategories.map((category, categoryIndex) => (
                        selectedCategory === 'all' || selectedCategory === category.name ? (
                            <motion.div
                                key={categoryIndex}
                                className="faq-category"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                            >
                                <div className="category-header">
                                    <category.icon className="category-icon" />
                                    <h2>{category.name}</h2>
                                </div>
                                <div className="questions-list">
                                    {category.questions.map((item, questionIndex) => {
                                        const key = `${categoryIndex}-${questionIndex}`;
                                        const isExpanded = expandedItems[key];

                                        return (
                                            <motion.div
                                                key={questionIndex}
                                                className={`question-item ${isExpanded ? 'expanded' : ''}`}
                                                initial={{ opacity: 0, y: 20 }}
                                                whileInView={{ opacity: 1, y: 0 }}
                                                viewport={{ once: true }}
                                            >
                                                <button
                                                    className="question-header"
                                                    onClick={() => toggleExpand(categoryIndex, questionIndex)}
                                                >
                                                    <h3>{item.q}</h3>
                                                    <ChevronDown className={`expand-icon ${isExpanded ? 'rotated' : ''}`} />
                                                </button>
                                                <AnimatePresence>
                                                    {isExpanded && (
                                                        <motion.div
                                                            className="answer"
                                                            initial={{ height: 0, opacity: 0 }}
                                                            animate={{ height: 'auto', opacity: 1 }}
                                                            exit={{ height: 0, opacity: 0 }}
                                                            transition={{ duration: 0.3 }}
                                                        >
                                                            <p>{item.a}</p>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </motion.div>
                                        );
                                    })}
                                </div>
                            </motion.div>
                        ) : null
                    ))}
                </div>
            </div>

            {/* Contact Section */}
            <div className="faq-contact">
                <div className="container">
                    <motion.div
                        className="contact-card"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Still Have Questions?</h2>
                        <p>Our support team is here to help you with any questions you might have.</p>
                        <div className="contact-buttons">
                            <a href="/contact" className="primary-button">
                                Contact Support
                                <MessageCircle size={20} />
                            </a>
                            <a href="/docs" className="secondary-button">
                                View Documentation
                            </a>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;