import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { motion } from 'framer-motion';
import { Mail, Check, ChevronRight } from 'lucide-react';
import './ContactPage.css';

// const contactMethods = [
//     {
//         icon: Mail,
//         title: "Email Us",
//         description: "Email us for general inquiries",
//         action: "Send Email",
//         link: "mailto:info@useargus.com"
//     },
// ];

const industries = [
    "Engineering & Construction",
    "Professional Services",
    "Manufacturing",
    "Research & Development",
    "Technology",
    "Other"
];

const ContactPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        industry: '',
        employees: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post('https://fw9otpni42.execute-api.ca-central-1.amazonaws.com/contact', formData);
            if (response.status === 200) {
                setIsSubmitted(true);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    company: '',
                    industry: '',
                    employees: '',
                    message: ''
                });
                setTimeout(() => setIsSubmitted(false), 3000);
            }
        } catch (err) {
            console.error(err);
            setError('There was an error submitting the form. Please try again later.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div className="contact-page">
            {/* Hero Section */}
            <motion.div 
                className="contact-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Get in Touch
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    Let's discuss how ARGUS can transform your organization's knowledge management
                </motion.p>
            </motion.div>

            {/* Contact Methods */}
            {/* <section className="contact-methods">
                <div className="container">
                    <div className="methods-grid">
                        {contactMethods.map((method, index) => (
                            <motion.a
                                key={index}
                                href={method.link}
                                className="method-card"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                                whileHover={{ y: -5 }}
                            >
                                <method.icon className="method-icon" />
                                <h3>{method.title}</h3>
                                <p>{method.description}</p>
                                <span className="method-action">
                                    {method.action}
                                    <ChevronRight size={16} />
                                </span>
                            </motion.a>
                        ))}
                    </div>
                </div>
            </section> */}

            {/* Contact Form */}
            <section className="contact-form-section">
                <div className="container">
                    <div className="form-content">
                        <motion.div 
                            className="form-header"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                        >
                            <h2>Tell us about your needs</h2>
                            <p>Fill out the form and we'll get back to you within 24 hours</p>
                        </motion.div>

                        <motion.form 
                            className="contact-form"
                            onSubmit={handleSubmit}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                        >
                            <div className="form-grid">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Work Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="industry">Industry</label>
                                    <select
                                        id="industry"
                                        name="industry"
                                        value={formData.industry}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Industry</option>
                                        {industries.map((industry, index) => (
                                            <option key={index} value={industry}>
                                                {industry}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="employees">Company Size</label>
                                    <select
                                        id="employees"
                                        name="employees"
                                        value={formData.employees}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Size</option>
                                        <option value="1-10">1-10 employees</option>
                                        <option value="11-50">11-50 employees</option>
                                        <option value="51-200">51-200 employees</option>
                                        <option value="201-500">201-500 employees</option>
                                        <option value="501+">501+ employees</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group full-width">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows={4}
                                    required
                                />
                            </div>
                            <motion.button
                                type="submit"
                                className="submit-button"
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                Send Message
                                <ChevronRight size={20} />
                            </motion.button>
                        </motion.form>

                        {error && (
                            <motion.div 
                                className="error-message"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                            >
                                <p>{error}</p>
                            </motion.div>
                        )}

                        <motion.div 
                            className={`submission-message ${isSubmitted ? 'show' : ''}`}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: isSubmitted ? 1 : 0, y: isSubmitted ? 0 : 10 }}
                        >
                            <Check size={20} />
                            Message sent successfully! We'll be in touch soon.
                        </motion.div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactPage;
