import React from 'react';
import { motion } from 'framer-motion';
import { FileSearch, List, FileText, CheckSquare } from 'lucide-react';
import { Link } from 'react-router-dom';

const BidAIShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-yellow-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row-reverse items-center">
          {/* Left side: Mock-up */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 mb-10 lg:mb-0"
          >
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="bg-gray-200 px-4 py-2 flex items-center justify-between">
                <span className="text-sm text-gray-700 font-medium">BidAI</span>
              </div>
              <div className="p-6">
                <div className="mb-4 p-4 bg-yellow-50 rounded">
                  <h3 className="font-semibold text-yellow-700 mb-2">RFP Analysis</h3>
                  <p className="text-sm text-gray-700">
                    BidAI has analyzed the RFP and extracted key requirements and specifications.
                  </p>
                </div>
                <div className="space-y-4">
                  <div className="p-3 bg-gray-100 rounded">
                    <h4 className="font-semibold text-gray-800 mb-2">Key Requirements:</h4>
                    <ul className="space-y-2">
                      <li className="flex items-center text-sm text-gray-700">
                        <CheckSquare className="w-4 h-4 text-green-500 mr-2" />
                        5+ years experience in software development
                      </li>
                      <li className="flex items-center text-sm text-gray-700">
                        <CheckSquare className="w-4 h-4 text-green-500 mr-2" />
                        Proficiency in React and Node.js
                      </li>
                      <li className="flex items-center text-sm text-gray-700">
                        <CheckSquare className="w-4 h-4 text-green-500 mr-2" />
                        Experience with cloud platforms (AWS preferred)
                      </li>
                    </ul>
                  </div>
                  <div className="p-3 bg-blue-50 rounded">
                    <h4 className="font-semibold text-blue-700 mb-2">Generated Proposal Sections:</h4>
                    <ul className="space-y-1">
                      <li className="text-sm text-gray-700">1. Executive Summary</li>
                      <li className="text-sm text-gray-700">2. Company Background</li>
                      <li className="text-sm text-gray-700">3. Proposed Solution</li>
                      <li className="text-sm text-gray-700">4. Timeline and Milestones</li>
                      <li className="text-sm text-gray-700">5. Budget and Pricing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right side: Text content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2 lg:pr-16"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">BidAI: Streamline Your Proposal Process</h2>
            <p className="text-xl mb-8 text-gray-700">
              Argus BidAI revolutionizes your RFP response process, automatically extracting requirements and generating tailored bid proposals.
            </p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-start text-gray-700">
                <FileSearch className="w-5 h-5 mr-2 text-yellow-500 mt-1 flex-shrink-0" />
                <span>Intelligent RFP analysis and requirement extraction</span>
              </li>
              <li className="flex items-start text-gray-700">
                <List className="w-5 h-5 mr-2 text-yellow-500 mt-1 flex-shrink-0" />
                <span>Automated generation of proposal outlines and content</span>
              </li>
              <li className="flex items-start text-gray-700">
                <FileText className="w-5 h-5 mr-2 text-yellow-500 mt-1 flex-shrink-0" />
                <span>Customizable templates for consistent, high-quality proposals</span>
              </li>
            </ul>
            <Link to='/features/bidai-contract-analysis'>
              <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300">
                Explore BidAI
              </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default BidAIShowcase;