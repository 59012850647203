import React from 'react';
import { motion } from 'framer-motion';
import { Search, Database, Brain, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

const ArgusLandingHero = () => {
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <div className="relative bg-gradient-to-br from-white to-blue-50 h-screen flex flex-col justify-center items-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-5xl sm:text-6xl md:text-7xl tracking-tight font-extrabold text-gray-900"
        >
          <span className="block">Unleash the Power of</span>
          <span className="block text-[#0068d7]">Your Organization's Knowledge</span>
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="mt-6 max-w-lg mx-auto text-xl sm:text-2xl text-gray-500"
        >
          Argus transforms your internal documents into a centralized, AI-driven knowledge hub.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-10 max-w-md mx-auto sm:flex sm:justify-center md:mt-12"
        >
          <div className="rounded-md shadow">
            <Link to="/contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#0068d7] hover:bg-blue-700 md:py-4 md:text-lg md:px-10 transition duration-300">
              Get started
            </Link>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-20 flex justify-center space-x-12"
        >
          <div className="text-center">
            <Search size={48} className="mx-auto text-[#0068d7]" />
            <p className="mt-2 text-sm font-medium text-gray-600">Smart Search</p>
          </div>
          <div className="text-center">
            <Database size={48} className="mx-auto text-[#0068d7]" />
            <p className="mt-2 text-sm font-medium text-gray-600">Centralized Data</p>
          </div>
          <div className="text-center">
            <Brain size={48} className="mx-auto text-[#0068d7]" />
            <p className="mt-2 text-sm font-medium text-gray-600">AI-Powered Insights</p>
          </div>
        </motion.div>
      </div>

      <motion.div
        className="absolute bottom-8 left-0 right-0 flex justify-center cursor-pointer"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        onClick={scrollToContent}
      >
        <ChevronDown size={32} className="text-[#0068d7] animate-bounce" />
      </motion.div>
    </div>
  );
};

export default ArgusLandingHero;