import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Cloud, Calendar, FileText, Database } from 'lucide-react';
import { Link } from 'react-router-dom';


const IntegrationIcon = ({ icon: Icon, name }) => (
  <div className="flex flex-col items-center">
    <div className="w-16 h-16 bg-white rounded-full shadow-md flex items-center justify-center mb-2">
      <Icon className="w-8 h-8 text-[#0068d7]" />
    </div>
    <span className="text-sm text-gray-700">{name}</span>
  </div>
);

const IntegrationsShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-blue-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left side: Integration icons */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 mb-10 lg:mb-0"
          >
            <div className="grid grid-cols-3 gap-8">
              <IntegrationIcon icon={Mail} name="Gmail" />
              <IntegrationIcon icon={Cloud} name="OneDrive" />
              <IntegrationIcon icon={Calendar} name="Outlook" />
              <IntegrationIcon icon={FileText} name="Google Drive" />
              <IntegrationIcon icon={Database} name="SharePoint" />
            </div>
          </motion.div>

          {/* Right side: Text content */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2 lg:pl-16"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Seamless Integrations</h2>
            <p className="text-xl mb-8 text-gray-700">
              Argus connects effortlessly with your existing tools and platforms, creating a unified knowledge ecosystem across your organization.
            </p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-start text-gray-700">
                <Cloud className="w-5 h-5 mr-2 text-blue-500 mt-1 flex-shrink-0" />
                <span>Sync and search across multiple cloud storage platforms</span>
              </li>
              <li className="flex items-start text-gray-700">
                <Mail className="w-5 h-5 mr-2 text-blue-500 mt-1 flex-shrink-0" />
                <span>Access and analyze email content for comprehensive insights</span>
              </li>
              <li className="flex items-start text-gray-700">
                <Database className="w-5 h-5 mr-2 text-blue-500 mt-1 flex-shrink-0" />
                <span>Integrate with SharePoint for enhanced collaboration</span>
              </li>
            </ul>
            <Link to='/features/integrations'>
              <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300">
                Explore Integrations
              </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationsShowcase;