import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { AlertCircle, ArrowRight, Bell } from 'lucide-react';
import './ComingSoonPage.css';

const ComingSoonPage = () => {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Extract page name from URL path
    const pageName = location.pathname
        .split('/')
        .filter(Boolean)
        .pop()
        ?.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically handle the email submission
        setIsSubmitted(true);
        // Reset form after 3 seconds
        setTimeout(() => {
            setIsSubmitted(false);
            setEmail('');
        }, 3000);
    };

    return (
        <div className="coming-soon-page">
            <div className="coming-soon-content">
                <motion.div
                    className="coming-soon-header"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <AlertCircle size={48} className="coming-soon-icon" />
                    <h1>Coming Soon</h1>
                    {pageName && (
                        <p className="page-name">
                            Our {pageName} page is under construction
                        </p>
                    )}
                </motion.div>

                <motion.div
                    className="coming-soon-message"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.6 }}
                >
                    <p>We're working hard to bring you something amazing.</p>
                    <p>Sign up to be notified when this content becomes available!</p>
                </motion.div>

                <motion.form
                    className="notification-form"
                    onSubmit={handleSubmit}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                >
                    <div className="input-wrapper">
                        <Bell className="input-icon" size={20} />
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button 
                        type="submit"
                        className="notify-button"
                    >
                        Notify Me
                        <ArrowRight size={20} />
                    </button>
                </motion.form>

                <motion.div
                    className={`submission-message ${isSubmitted ? 'show' : ''}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: isSubmitted ? 1 : 0, y: isSubmitted ? 0 : 10 }}
                >
                    Thank you! We'll notify you when this stuff is available.
                </motion.div>
            </div>

            {/* Decorative elements */}
            <div className="coming-soon-background">
                <div className="gradient-sphere gradient-sphere-1"></div>
                <div className="gradient-sphere gradient-sphere-2"></div>
                <div className="gradient-sphere gradient-sphere-3"></div>
            </div>
        </div>
    );
};

export default ComingSoonPage;