import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { NavLink, Link } from 'react-router-dom';
import { Menu, X, Twitter, Linkedin, ChevronDown, Brain, Database, CloudLightning, Network, Lock, Bot, Key, BarChart3 } from 'lucide-react';
import './MainLayout.css';

const featuresDropdownData = [
    {
        icon: Brain,
        title: "AI-Powered Search & Chat",
        description: "Advanced AI that understands context and intent",
        path: "/features/ai-search-chat"
    },
    {
        icon: Database,
        title: "Document Management",
        description: "Comprehensive document organization system",
        path: "/features/document-management"
    },
    {
        icon: CloudLightning,
        title: "BidAI & Contracts",
        description: "Automated RFP and contract analysis",
        path: "/features/bidai-contract-analysis"
    },
    {
        icon: Network,
        title: "RecruitAI",
        description: "Intelligent talent matching and assessment",
        path: "/features/recruitai-talent"
    },
    {
        icon: Lock,
        title: "Enterprise Security",
        description: "Military-grade security measures",
        path: "/features/enterprise-security"
    },
    {
        icon: Bot,
        title: "AI Assistant",
        description: "Context-aware AI companion",
        path: "/features/ai-assistant"
    },
    {
        icon: Key,
        title: "Integrations",
        description: "Connect with your existing tools",
        path: "/features/integrations"
    },
    {
        icon: BarChart3,
        title: "Analytics",
        description: "Insights into your knowledge base",
        path: "/features/analytics-insights"
    }
];

const FeaturesDropdown = ({ isOpen, onClose }) => (
    <AnimatePresence>
        {isOpen && (
            <motion.div
                className="features-dropdown"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
            >
                <div className="features-dropdown-grid">
                    {featuresDropdownData.map((feature, index) => {
                        const Icon = feature.icon;
                        return (
                            <Link
                                key={index}
                                to={feature.path}
                                className="feature-dropdown-item"
                                onClick={onClose}
                            >
                                <div className="feature-dropdown-icon">
                                    <Icon size={24} />
                                </div>
                                <div className="feature-dropdown-content">
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </div>
                            </Link>
                        );
                    })}
                </div>
                <div className="features-dropdown-footer">
                    <Link to="/features" className="features-dropdown-all" onClick={onClose}>
                        View All Features
                        <ChevronDown className="rotate-270" />
                    </Link>
                </div>
            </motion.div>
        )}
    </AnimatePresence>
);



const MainLayout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const menuItems = [
        { path: '/solutions', label: 'Solutions' },
        // { path: '/resources', label: 'Resources' },
    ];

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const footerLinks = {
        product: [
            { label: 'Features', path: '/features' },
            { label: 'Solutions', path: '/solutions' },
            { label: 'Pricing', path: '/pricing' },
            { label: 'Updates', path: '/updates' },
        ],
        // resources: [
        //     { label: 'Documentation', path: '/docs' },
        //     { label: 'Tutorials', path: '/tutorials' },
        //     { label: 'Case Studies', path: '/case-studies' },
        //     { label: 'FAQs', path: '/faqs' },
        // ],
        company: [
            { label: 'About Us', path: '/about' },
            // { label: 'Careers', path: '/careers' },
            { label: 'Contact', path: '/contact' },
            // { label: 'Partners', path: '/partners' },
        ],
        legal: [
            { label: 'Privacy Policy', path: '/privacy' },
            { label: 'Terms of Service', path: '/terms' },
            { label: 'Cookie Policy', path: '/cookie-policy' },
        ],
    };

    return (
        <div className="main-layout">
            <header className="main-layout-header">
                <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <Link to="/" className="flex-shrink-0 flex items-center">
                                <span className="text-2xl font-bold" style={{ color: 'var(--argus-primary)' }}>ARGUS</span>
                            </Link>
                            <div className="nav-items-container">
                                {/* Features Dropdown */}
                                <div className="relative" ref={dropdownRef}>
                                    <button
                                        className={`features-dropdown-trigger ${isDropdownOpen ? 'active' : ''}`}
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    >
                                        Features
                                        <ChevronDown
                                            className={`transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                                            size={16}
                                        />
                                    </button>
                                    <FeaturesDropdown
                                        isOpen={isDropdownOpen}
                                        onClose={() => setIsDropdownOpen(false)}
                                    />
                                </div>

                                {/* Other menu items */}
                                {menuItems.map((item) => (
                                    <NavLink
                                        key={item.path}
                                        to={item.path}
                                        className="nav-link"
                                    >
                                        {item.label}
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:items-center">
                            <a
                                href="https://dev.useargus.com"
                                className="sign-in-button mr-3"
                            >
                                Sign in
                            </a>
                            <NavLink to="/contact" className="demo-button">
                                Get a demo
                            </NavLink>
                        </div>
                        <div className="-mr-2 flex items-center sm:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset"
                                style={{ '--tw-ring-color': 'var(--argus-primary)' }}
                            >
                                <span className="sr-only">Open main menu</span>
                                {isOpen ? <X size={24} /> : <Menu size={24} />}
                            </button>
                        </div>
                    </div>
                </nav>

                <motion.div
                    className={`sm:hidden ${isOpen ? 'block' : 'hidden'}`}
                    initial={false}
                    animate={isOpen ? { height: 'auto' } : { height: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {/* Mobile menu content here */}
                </motion.div>
            </header>

            <main className="flex-grow">
                {children}
            </main>

            <footer className="main-layout-footer">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        {Object.entries(footerLinks).map(([category, links]) => (
                            <div key={category}>
                                <h3 className="text-lg font-semibold mb-4 capitalize">{category}</h3>
                                <ul className="space-y-2">
                                    {links.map((link) => (
                                        <li key={link.path}>
                                            <Link to={link.path} className="footer-link">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="mt-12 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
                        <p>&copy; 2024 ARGUS. All rights reserved.</p>
                        <div className="flex space-x-6 mt-4 md:mt-0">
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default MainLayout;