import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Building2, HardHat, Briefcase, GraduationCap, ChevronRight, BadgeCheck } from 'lucide-react';
import './SolutionsPage.css';
import { Link } from 'react-router-dom';

const industries = [
  {
    icon: Building2,
    title: "Engineering & Construction",
    description: "Streamline project documentation, automate RFP responses, and manage engineering requirements efficiently.",
    benefits: [
      {
        title: "Faster Bid Response",
        description: "Cut RFP response time by 60% with AI-powered document analysis and automated proposal generation.",
        metric: "60% faster"
      },
      {
        title: "Requirements Management",
        description: "Automatically extract and track engineering requirements from project documents and specifications.",
        metric: "95% accuracy"
      },
      {
        title: "Document Control",
        description: "Centralize and version control all project documentation with intelligent search capabilities.",
        metric: "100% compliance"
      }
    ]
  },
  {
    icon: Briefcase,
    title: "Professional Services",
    description: "Enhance knowledge sharing, improve proposal win rates, and accelerate client deliverables.",
    benefits: [
      {
        title: "Knowledge Reuse",
        description: "Find and leverage past work to improve quality and consistency of deliverables.",
        metric: "40% time saved"
      },
      {
        title: "Proposal Success",
        description: "Increase win rates by leveraging successful past proposals and best practices.",
        metric: "35% higher wins"
      },
      {
        title: "Client Satisfaction",
        description: "Deliver faster responses to client queries with instant access to relevant information.",
        metric: "90% satisfaction"
      }
    ]
  },
  {
    icon: HardHat,
    title: "Manufacturing",
    description: "Optimize documentation management, streamline compliance, and improve knowledge transfer.",
    benefits: [
      {
        title: "Standard Operating Procedures",
        description: "Keep SOPs up-to-date and easily accessible across your organization.",
        metric: "99% accuracy"
      },
      {
        title: "Quality Control",
        description: "Ensure consistent quality with instant access to specifications and procedures.",
        metric: "45% fewer errors"
      },
      {
        title: "Training Efficiency",
        description: "Accelerate onboarding and training with intelligent knowledge access.",
        metric: "50% faster training"
      }
    ]
  },
  {
    icon: GraduationCap,
    title: "Research & Development",
    description: "Accelerate innovation by connecting research insights across your organization.",
    benefits: [
      {
        title: "Research Insights",
        description: "Discover connections across research documents and data sets automatically.",
        metric: "3x faster research"
      },
      {
        title: "IP Protection",
        description: "Secure intellectual property with granular access controls and tracking.",
        metric: "100% secure"
      },
      {
        title: "Innovation Speed",
        description: "Reduce time to market by leveraging existing research and avoiding duplication.",
        metric: "40% faster delivery"
      }
    ]
  }
];

const IndustryCard = ({ icon: Icon, title, description, benefits }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div 
      className="industry-card"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
    >
      <div className="industry-header">
        <motion.div 
          className="industry-icon-wrapper"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
        >
          <Icon className="industry-icon" />
          <div className="industry-icon-glow" />
        </motion.div>
        <div>
          <h3 className="industry-title">{title}</h3>
          <p className="industry-description">{description}</p>
        </div>
      </div>

      <motion.div 
        className="industry-benefits"
        initial="collapsed"
        animate={isExpanded ? "expanded" : "collapsed"}
        variants={{
          expanded: { opacity: 1, height: "auto" },
          collapsed: { opacity: 0, height: 0 }
        }}
      >
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-item">
            <div className="benefit-header">
              <BadgeCheck className="benefit-check" />
              <h4 className="benefit-title">{benefit.title}</h4>
              <span className="benefit-metric">{benefit.metric}</span>
            </div>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </motion.div>

      <motion.button
        className="industry-expand-button"
        onClick={() => setIsExpanded(!isExpanded)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {isExpanded ? 'Show Less' : 'See Benefits'}
        <ChevronRight className={`expand-icon ${isExpanded ? 'rotated' : ''}`} />
      </motion.button>
    </motion.div>
  );
};

const SolutionsPage = () => {
  return (
    <div className="solutions-page">
      <div className="solutions-hero">
        <motion.h1 
          className="solutions-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Solutions for Your <span className="gradient-text">Industry</span>
        </motion.h1>
        <motion.p 
          className="solutions-description"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          ARGUS adapts to your industry's unique needs, delivering measurable business value through intelligent knowledge management.
        </motion.p>
      </div>

      <div className="industries-grid">
        {industries.map((industry, index) => (
          <IndustryCard key={index} {...industry} />
        ))}
      </div>

      <div className="solutions-cta">
        <h2>Not seeing your industry?</h2>
        <p>Contact us to learn how ARGUS can be customized for your specific needs.</p>
        <Link to='/contact'>
        <motion.button 
          className="solution-cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Schedule a Consultation
          <ChevronRight className="cta-arrow" />
        </motion.button>
      </Link>
      </div>
    </div>
  );
};

export default SolutionsPage;