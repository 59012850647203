import React from 'react';
import { motion } from 'framer-motion';
import { 
    Rocket, 
    Target, 
    Users, 
    Shield, 
    Globe, 
    Brain, 
    ChevronRight,
    Award,
    Zap
} from 'lucide-react';
import './AboutPage.css';

const values = [
    {
        icon: Brain,
        title: "Innovation First",
        description: "We push the boundaries of AI technology to solve real-world problems."
    },
    {
        icon: Shield,
        title: "Trust & Security",
        description: "We maintain the highest standards of data security and privacy."
    },
    {
        icon: Users,
        title: "Customer Success",
        description: "Your success is our success. We're committed to your growth."
    },
    {
        icon: Globe,
        title: "Global Impact",
        description: "We're building solutions that make a difference worldwide."
    }
];

const milestones = [
    {
        year: "2022",
        title: "The Beginning",
        description: "ARGUS was founded with a vision to transform how organizations manage and utilize their knowledge."
    },
    {
        year: "2023",
        title: "Initial Launch",
        description: "Released our core AI-powered search and knowledge management platform."
    },
    {
        year: "2024",
        title: "Rapid Growth",
        description: "Expanded our product suite with BidAI and RecruitAI, serving enterprises worldwide."
    },
    {
        year: "2025",
        title: "Future Vision",
        description: "Continuing to innovate and expand our AI capabilities to serve more industries."
    }
];

const stats = [
    {
        number: "500+",
        label: "Enterprise Clients"
    },
    {
        number: "50M+",
        label: "Documents Processed"
    },
    {
        number: "99.9%",
        label: "Uptime"
    },
    {
        number: "24/7",
        label: "Support"
    }
];

const AboutPage = () => {
    return (
        <div className="about-page">
            {/* Hero Section */}
            <motion.section 
                className="about-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Transforming Knowledge Management with <span className="gradient-text">AI</span>
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="hero-description"
                >
                    ARGUS is building the future of enterprise intelligence, helping organizations unlock the full potential of their knowledge.
                </motion.p>
            </motion.section>

            {/* Mission Section */}
            <section className="mission-section">
                <div className="container">
                    <motion.div 
                        className="mission-content"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <div className="mission-text">
                            <h2>Our Mission</h2>
                            <p>To empower organizations with AI-driven knowledge management solutions that enhance decision-making, accelerate innovation, and drive success in the digital age.</p>
                            <div className="mission-stats">
                                {stats.map((stat, index) => (
                                    <motion.div 
                                        key={index} 
                                        className="stat-item"
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        viewport={{ once: true }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <span className="stat-number">{stat.number}</span>
                                        <span className="stat-label">{stat.label}</span>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                        <div className="mission-visual">
                            <Target className="mission-icon" />
                        </div>
                    </motion.div>
                </div>
            </section>

            {/* Values Section */}
            <section className="values-section">
                <div className="container">
                    <h2>Our Values</h2>
                    <div className="values-grid">
                        {values.map((value, index) => (
                            <motion.div 
                                key={index}
                                className="value-card"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                            >
                                <value.icon className="value-icon" />
                                <h3>{value.title}</h3>
                                <p>{value.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Journey Section */}
            <section className="journey-section">
                <div className="container">
                    <h2>Our Journey</h2>
                    <div className="timeline">
                        {milestones.map((milestone, index) => (
                            <motion.div 
                                key={index}
                                className="timeline-item"
                                initial={{ opacity: 0, x: -20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                            >
                                <div className="timeline-marker"></div>
                                <div className="timeline-content">
                                    <span className="year">{milestone.year}</span>
                                    <h3>{milestone.title}</h3>
                                    <p>{milestone.description}</p>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>


            {/* CTA Section */}
            <section className="about-cta">
                <div className="container">
                    <motion.div 
                        className="cta-content"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Join Us on Our Mission</h2>
                        <p>Experience how ARGUS can transform your organization's knowledge management.</p>
                        <div className="cta-buttons">
                            <a href="/demo" className="primary-button">
                                Get Started
                                <ChevronRight size={20} />
                            </a>
                            <a href="/careers" className="secondary-button">
                                View Careers
                            </a>
                        </div>
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default AboutPage;