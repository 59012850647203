import React from 'react';
import { motion } from 'framer-motion';
import { useParams, Navigate } from 'react-router-dom';
import { ChevronRight, Play, Check } from 'lucide-react';
import { featuresData } from './FeaturesPage'; // Import the data from your features page
import './FeatureDetailPage.css';
import { Link } from 'react-router-dom';

const FeatureDetailPage = () => {
    const { slug } = useParams();
    const feature = featuresData.find(f => f.slug === slug);

    if (!feature) {
        return <Navigate to="/features" replace />;
    }

    const Icon = feature.icon;

    return (
        <div className="feature-detail-page">
            {/* Hero Section */}
            <motion.div
                className="feature-detail-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <div className="feature-detail-hero-content">
                    <motion.div
                        className="feature-detail-icon-wrapper"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <Icon className="feature-detail-icon" />
                    </motion.div>
                    <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                    >
                        {feature.title}
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                        className="feature-detail-description"
                    >
                        {feature.description}
                    </motion.p>
                    <motion.div
                        className="feature-detail-cta"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5 }}
                    >
                        {/* <Link to='/demo'>
                            <button className="primary-button">
                                <Play size={20} />
                                Watch Demo
                            </button>
                        </Link> */}
                        {/* <button className="secondary-button">
                            Learn More
                            <ChevronRight size={20} />
                        </button> */}
                    </motion.div>
                </div>
            </motion.div>

            {/* Key Points Section */}
            <section className="feature-detail-keypoints">
                <div className="container">
                    <h2>Key Features</h2>
                    <div className="keypoints-grid">
                        {feature.detailContent.keyPoints.map((point, index) => (
                            <motion.div
                                key={index}
                                className="keypoint-card"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                            >
                                <h3>{point.title}</h3>
                                <p>{point.description}</p>
                                <ul className="benefits-list">
                                    {point.benefits.map((benefit, idx) => (
                                        <li key={idx}>
                                            <Check size={16} />
                                            <span>{benefit}</span>
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Overview Section */}
            <section className="feature-detail-overview">
                <div className="container">
                    <div className="overview-content">
                        <motion.div
                            className="overview-text"
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                        >
                            <h2>Overview</h2>
                            <p>{feature.detailContent.overview}</p>
                        </motion.div>
                        <motion.div
                            className="overview-visual"
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                        >
                            <div className="overview-icon">
                                <Icon size={120} />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="feature-detail-cta-section">
                <div className="container">
                    <motion.div
                        className="cta-content"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Ready to Get Started?</h2>
                        <p>See how {feature.title} can transform your organization.</p>
                        <div className="cta-buttons">
                            <Link to='/contact'>
                                <button className="primary-button">
                                    Schedule Demo
                                    <ChevronRight size={20} />
                                </button>
                            </Link>
                            <Link to='/contact'>
                            <button className="secondary-button">
                                Contact Sales
                            </button>
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default FeatureDetailPage;