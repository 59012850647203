import React from 'react';
import { motion } from 'framer-motion';
import { Users, FileText, Briefcase, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const RecruitAIShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-purple-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left side: Mock-up */}
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 mb-10 lg:mb-0"
          >
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="bg-gray-200 px-4 py-2 flex items-center justify-between">
                <span className="text-sm text-gray-700 font-medium">RecruitAI</span>
              </div>
              <div className="p-6">
                <div className="mb-4 p-4 bg-purple-50 rounded">
                  <h3 className="font-semibold text-purple-700 mb-2">Job Description</h3>
                  <p className="text-sm text-gray-700">
                    Senior Software Engineer needed for a fast-paced startup. 5+ years of experience with React, Node.js, and AWS required. Strong problem-solving skills and ability to work in a team environment.
                  </p>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center justify-between p-3 bg-gray-100 rounded">
                    <div className="flex items-center">
                      <Users className="w-5 h-5 text-blue-500 mr-2" />
                      <span className="text-sm text-gray-700">John Doe</span>
                    </div>
                    <span className="text-sm font-semibold text-green-500">98% Match</span>
                  </div>
                  <div className="flex items-center justify-between p-3 bg-gray-100 rounded">
                    <div className="flex items-center">
                      <Users className="w-5 h-5 text-blue-500 mr-2" />
                      <span className="text-sm text-gray-700">Jane Smith</span>
                    </div>
                    <span className="text-sm font-semibold text-green-500">95% Match</span>
                  </div>
                  <div className="flex items-center justify-between p-3 bg-gray-100 rounded">
                    <div className="flex items-center">
                      <Users className="w-5 h-5 text-blue-500 mr-2" />
                      <span className="text-sm text-gray-700">Bob Johnson</span>
                    </div>
                    <span className="text-sm font-semibold text-yellow-500">85% Match</span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          
          {/* Right side: Text content */}
          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2 lg:pl-16"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">RecruitAI: Revolutionize Your Hiring</h2>
            <p className="text-xl mb-8 text-gray-700">
              Argus RecruitAI transforms your recruitment process by intelligently matching candidates to job roles, saving time and improving hiring outcomes.
            </p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-start text-gray-700">
                <FileText className="w-5 h-5 mr-2 text-purple-500 mt-1 flex-shrink-0" />
                <span>Automatically ingest and analyze resumes at scale</span>
              </li>
              <li className="flex items-start text-gray-700">
                <Briefcase className="w-5 h-5 mr-2 text-purple-500 mt-1 flex-shrink-0" />
                <span>Match candidates to job descriptions using advanced AI algorithms</span>
              </li>
              <li className="flex items-start text-gray-700">
                <CheckCircle className="w-5 h-5 mr-2 text-purple-500 mt-1 flex-shrink-0" />
                <span>Identify top candidates quickly and efficiently</span>
              </li>
            </ul>
            <Link to='/features/recruitai-talent'>
            <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300">
              Explore RecruitAI
            </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default RecruitAIShowcase;