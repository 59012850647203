import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Search, FileText, Brain, Zap, Lock, Bot, Code, CloudLightning, Network, Database, Key, BarChart3, ChevronRight } from 'lucide-react';
import './FeaturesPage.css';

// Detailed features data with slugs and full content
export const featuresData = [
  {
    icon: Brain,
    slug: "ai-search-chat",
    title: "AI-Powered Search & Chat",
    description: "Experience the next generation of enterprise search with our advanced AI that understands context and intent.",
    shortItems: [
      "Natural language processing for intuitive searching",
      "Context-aware chat interface with source citations",
      "Cross-document knowledge synthesis",
      "Multi-language support and semantic understanding"
    ],
    detailContent: {
      overview: "Unlock the full potential of your organization's knowledge with ARGUS's advanced AI search and chat capabilities. Our system goes beyond traditional search, understanding context and intent to deliver precise answers.",
      keyPoints: [
        {
          title: "Natural Language Understanding",
          description: "Ask questions in plain English and receive accurate, contextual responses.",
          benefits: [
            "Intuitive query processing",
            "Context retention across conversations",
            "Multi-language support",
            "Semantic search capabilities"
          ]
        },
        {
          title: "Source-Grounded Responses",
          description: "Every answer comes with citations and links to source documents.",
          benefits: [
            "Automatic citation generation",
            "Direct source linking",
            "Quote extraction",
            "Context preservation"
          ]
        },
        {
          title: "Cross-Document Intelligence",
          description: "Connect information across your entire document base.",
          benefits: [
            "Automatic relationship mapping",
            "Knowledge synthesis",
            "Pattern recognition",
            "Insight generation"
          ]
        }
      ]
    }
  },
  {
    icon: Database,
    slug: "document-management",
    title: "Intelligent Document Management",
    description: "Transform how your organization handles documents with our comprehensive management system.",
    shortItems: [
      "Automated document categorization and tagging",
      "Version control and change tracking",
      "Advanced permission management",
      "Seamless integration with existing storage solutions"
    ],
    detailContent: {
      overview: "Modernize your document management with AI-powered organization, intelligent categorization, and seamless integration capabilities.",
      keyPoints: [
        {
          title: "Smart Organization",
          description: "Automatically categorize and tag documents for easy retrieval.",
          benefits: [
            "AI-powered categorization",
            "Automatic metadata generation",
            "Smart tagging system",
            "Hierarchical organization"
          ]
        },
        {
          title: "Version Control",
          description: "Track changes and maintain document history with precision.",
          benefits: [
            "Complete revision history",
            "Change tracking",
            "Version comparison",
            "Rollback capabilities"
          ]
        },
        {
          title: "Access Control",
          description: "Manage permissions and ensure document security.",
          benefits: [
            "Role-based access",
            "Granular permissions",
            "Activity logging",
            "Security compliance"
          ]
        }
      ]
    }
  },
  // Add similar detailed structures for other features...
  {
    icon: CloudLightning,
    slug: "bidai-contract-analysis",
    title: "BidAI & Contract Analysis",
    description: "Revolutionize your RFP process and contract management with AI-powered automation.",
    shortItems: [
      "Automated RFP response generation",
      "Requirement extraction and tracking",
      "Contract analysis and risk assessment",
      "Compliance checking and validation"
    ],
    detailContent: {
      overview: "Transform your bidding process and contract management with AI-powered automation that understands complex requirements, generates responses, and ensures compliance.",
      keyPoints: [
        {
          title: "Automated RFP Processing",
          description: "Automatically analyze RFPs and generate winning responses.",
          benefits: [
            "60% faster response time",
            "Requirements auto-extraction",
            "Smart template matching",
            "Past proposal learning"
          ]
        },
        {
          title: "Contract Intelligence",
          description: "Deep analysis of contracts for risks and obligations.",
          benefits: [
            "Risk identification",
            "Obligation tracking",
            "Clause comparison",
            "Compliance validation"
          ]
        },
        {
          title: "Requirement Management",
          description: "Track and manage requirements throughout the project lifecycle.",
          benefits: [
            "Automated tracking",
            "Change impact analysis",
            "Progress monitoring",
            "Dependency mapping"
          ]
        }
      ]
    }
  },
  {
    icon: Network,
    slug: "recruitai-talent",
    title: "RecruitAI & Talent Management",
    description: "Transform your recruitment process with intelligent candidate matching and assessment.",
    shortItems: [
      "AI-powered resume parsing and analysis",
      "Smart candidate-job matching",
      "Automated skill assessment",
      "Interview question generation and scoring"
    ],
    detailContent: {
      overview: "Streamline your recruitment process with AI that understands candidate qualifications, matches them to roles, and helps you make better hiring decisions.",
      keyPoints: [
        {
          title: "Intelligent Resume Analysis",
          description: "Automatically extract and understand candidate qualifications.",
          benefits: [
            "Skill extraction",
            "Experience validation",
            "Education verification",
            "Qualification scoring"
          ]
        },
        {
          title: "Smart Matching Engine",
          description: "Match candidates to roles based on multiple criteria.",
          benefits: [
            "Skill alignment",
            "Experience matching",
            "Culture fit assessment",
            "Growth potential analysis"
          ]
        },
        {
          title: "Interview Assistance",
          description: "Generate relevant questions and evaluate responses.",
          benefits: [
            "Custom question generation",
            "Response evaluation",
            "Feedback automation",
            "Interview scoring"
          ]
        }
      ]
    }
  },
  {
    icon: Lock,
    slug: "enterprise-security",
    title: "Enterprise-Grade Security",
    description: "Rest easy knowing your sensitive data is protected by military-grade security measures.",
    shortItems: [
      "End-to-end AES-256 encryption",
      "Role-based access control (RBAC)",
      "Comprehensive audit logging",
      "Compliance with major security standards"
    ],
    detailContent: {
      overview: "Protect your organization's knowledge with enterprise-grade security that ensures data privacy, controls access, and maintains compliance.",
      keyPoints: [
        {
          title: "Advanced Encryption",
          description: "Protect data with military-grade encryption standards.",
          benefits: [
            "AES-256 encryption",
            "End-to-end protection",
            "Key management",
            "Secure transmission"
          ]
        },
        {
          title: "Access Control",
          description: "Granular control over who can access what information.",
          benefits: [
            "Role-based access",
            "Permission management",
            "User authentication",
            "Session control"
          ]
        },
        {
          title: "Compliance & Auditing",
          description: "Meet security standards and track all system activity.",
          benefits: [
            "Activity logging",
            "Compliance reporting",
            "Security alerts",
            "Audit trails"
          ]
        }
      ]
    }
  },
  {
    icon: Bot,
    slug: "ai-assistant",
    title: "Intelligent AI Assistant",
    description: "Your team's AI companion for navigating and utilizing your organization's knowledge base.",
    shortItems: [
      "Context-aware conversations",
      "Document-grounded responses",
      "Multi-turn dialogue support",
      "Customizable AI behavior"
    ],
    detailContent: {
      overview: "Experience a new level of productivity with an AI assistant that understands your organization's context and helps teams work smarter.",
      keyPoints: [
        {
          title: "Contextual Understanding",
          description: "Maintain context across complex conversations.",
          benefits: [
            "Thread memory",
            "Context retention",
            "Intent recognition",
            "Personalized responses"
          ]
        },
        {
          title: "Knowledge Integration",
          description: "Access and utilize your organization's knowledge base.",
          benefits: [
            "Document grounding",
            "Cross-reference capability",
            "Source citation",
            "Knowledge synthesis"
          ]
        },
        {
          title: "Workflow Automation",
          description: "Automate routine tasks and processes.",
          benefits: [
            "Task automation",
            "Process optimization",
            "Workflow integration",
            "Custom actions"
          ]
        }
      ]
    }
  },
  {
    icon: Key,
    slug: "integrations",
    title: "Seamless Integrations",
    description: "Connect ARGUS with your existing tools and workflows for maximum efficiency.",
    shortItems: [
      "Google Workspace integration",
      "Microsoft 365 compatibility",
      "Popular CRM system connections",
      "Custom API support"
    ],
    detailContent: {
      overview: "Integrate ARGUS seamlessly with your existing tools and platforms to create a unified knowledge ecosystem.",
      keyPoints: [
        {
          title: "Workplace Integration",
          description: "Connect with popular workplace tools and platforms.",
          benefits: [
            "Google Workspace sync",
            "Microsoft 365 integration",
            "Slack connectivity",
            "Email integration"
          ]
        },
        {
          title: "CRM Integration",
          description: "Connect with your customer relationship management systems.",
          benefits: [
            "Salesforce integration",
            "HubSpot connection",
            "Custom CRM support",
            "Data synchronization"
          ]
        },
        {
          title: "API & Custom Tools",
          description: "Build custom integrations with our robust API.",
          benefits: [
            "RESTful API access",
            "Custom webhooks",
            "SDK support",
            "Integration templates"
          ]
        }
      ]
    }
  },
  {
    icon: BarChart3,
    slug: "analytics-insights",
    title: "Analytics & Insights",
    description: "Gain valuable insights into your organization's knowledge and its utilization.",
    shortItems: [
      "Usage pattern analysis",
      "Knowledge gap identification",
      "ROI measurement tools",
      "Custom reporting capabilities"
    ],
    detailContent: {
      overview: "Transform your organizational data into actionable insights with powerful analytics tools and custom reporting capabilities.",
      keyPoints: [
        {
          title: "Usage Analytics",
          description: "Understand how your knowledge base is being utilized.",
          benefits: [
            "Usage tracking",
            "Pattern recognition",
            "User engagement metrics",
            "Trend analysis"
          ]
        },
        {
          title: "Knowledge Insights",
          description: "Identify knowledge gaps and opportunities.",
          benefits: [
            "Gap analysis",
            "Content recommendations",
            "Topic clustering",
            "Impact assessment"
          ]
        },
        {
          title: "Custom Reporting",
          description: "Generate detailed reports tailored to your needs.",
          benefits: [
            "Report customization",
            "Data visualization",
            "Export capabilities",
            "Scheduled reporting"
          ]
        }
      ]
    }
  }
];

const FeatureSection = ({ title, description, icon: Icon, shortItems, slug, imageRight = false }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99],
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const iconVariants = {
    hover: {
      scale: 1.1,
      rotate: 5,
      transition: {
        duration: 0.2,
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  const handleLearnMore = () => {
    navigate(`/features/${slug}`);
  };

  return (
    <motion.div
      className={`feature-section ${isExpanded ? 'expanded' : ''}`}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
    >
      <div className="feature-section-container">
        <motion.div className={`feature-content ${imageRight ? 'feature-content-right' : ''}`}>
          <motion.div
            className="feature-icon-wrapper"
            whileHover="hover"
            variants={iconVariants}
          >
            <Icon className="feature-icon" />
            <div className="feature-icon-glow" />
          </motion.div>

          <motion.h2
            className="feature-title"
            variants={itemVariants}
          >
            {title}
          </motion.h2>

          <motion.p
            className="feature-description"
            variants={itemVariants}
          >
            {description}
          </motion.p>

          <motion.div className="feature-list-container">
            <motion.ul className="feature-list">
              {shortItems.slice(0, isExpanded ? shortItems.length : 2).map((item, idx) => (
                <motion.li
                  key={idx}
                  className="feature-list-item"
                  initial={idx >= 2 ? { opacity: 0, x: -20 } : false}
                  animate={idx >= 2 && isExpanded ? { opacity: 1, x: 0 } : false}
                  variants={idx < 2 ? itemVariants : undefined}
                >
                  <div className="feature-list-icon-wrapper">
                    <Zap className="feature-list-icon" />
                  </div>
                  <span className="feature-list-text">{item}</span>
                </motion.li>
              ))}
            </motion.ul>

            <motion.button
              className="feature-expand-button"
              onClick={handleLearnMore}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Learn more
              <ChevronRight className={`expand-icon ${isExpanded ? 'rotated' : ''}`} />
            </motion.button>
          </motion.div>
        </motion.div>

        <motion.div
          className={`feature-visual ${imageRight ? 'feature-visual-left' : ''}`}
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 0.6,
              ease: [0.6, -0.05, 0.01, 0.99]
            }
          }}
          viewport={{ once: true }}
        >
          <motion.div
            className="feature-visual-container"
            animate={{ rotate: [0, 360] }}
            transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
          >
            <Icon className="feature-visual-icon" />
          </motion.div>
          <div className="feature-visual-gradient" />
        </motion.div>
      </div>
    </motion.div>
  );
};

const HeroSection = () => (
  <motion.div
    className="features-hero"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: [0.6, -0.05, 0.01, 0.99] }}
  >
    <motion.h1
      className="features-hero-title"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.8 }}
    >
      Enterprise Intelligence,{' '}
      <span className="gradient-text">Unleashed</span>
    </motion.h1>
    <motion.p
      className="features-hero-description"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.8 }}
    >
      ARGUS combines cutting-edge AI technology with enterprise-grade security to transform how your organization manages and utilizes information. Discover the features that make ARGUS the ultimate knowledge management solution.
    </motion.p>
  </motion.div>
);

const CTASection = () => (
  <motion.div
    className="features-cta"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
  >
    <h2 className="features-cta-title">
      Ready to Transform Your Organization?
    </h2>
    <p className="features-cta-description">
      Join the leading companies using ARGUS to revolutionize their knowledge management.
    </p>
    <motion.button
      className="features-cta-button"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      Get Started Today
      <ChevronRight className="cta-arrow" />
    </motion.button>
  </motion.div>
);

const FeaturesPage = () => {
  return (
    <div className="features-page">
      <HeroSection />
      {featuresData.map((feature, index) => (
        <FeatureSection
          key={index}
          {...feature}
          imageRight={index % 2 === 1}
        />
      ))}
      <CTASection />
    </div>
  );
};

export default FeaturesPage;