import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import ArgusLandingHero from './components/ArgusLandingHero';
import FeaturesPage from './components/FeaturesPage';
import SolutionsPage from './components/SolutionsPage';
import ResourcesPage from './components/ResourcesPage';
import ContactPage from './components/ContactPage';
import './styles/globals.css';
import OverviewSection from './components/OverviewSection';
import KeyFeaturesSection from './components/KeyFeaturesSection';
import AIAssistantShowcase from './components/AIAssistantShowcase';
import DocumentManagementShowcase from './components/DocumentManagementShowcase';
import RecruitAIShowcase from './components/RecruitAIShowcase';
import BidAIShowcase from './components/BidAIShowcase';
import IntegrationsShowcase from './components/IntegrationsShowcase';
import SecurityShowcase from './components/SecurityShowcase';
import FeatureDetailPage from './components/FeatureDetailPage';
import ComingSoonPage from './components/ComingSoonPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPages';
import TermsOfServicePage from './components/TermsOfServicePage';
import CookiePolicyPage from './components/CookiePolicyPage';
import AboutPage from './components/About';
import FAQPage from './components/FAQPage';
import ScrollToTop from './utilities/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <MainLayout>
        <Routes>
          <Route path="/" element={
            <>
              <ArgusLandingHero />
              <AIAssistantShowcase />
              <DocumentManagementShowcase />
              <RecruitAIShowcase />
              <BidAIShowcase />
              <IntegrationsShowcase />
              <SecurityShowcase />
            </>
          } />
          <Route path="/features/:slug" element={<FeatureDetailPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/resources" element={<ComingSoonPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/demo" element={<ComingSoonPage />} />
          <Route path="/docs" element={<ComingSoonPage />} />
          <Route path="/tutorials" element={<ComingSoonPage />} />
          <Route path="/case-studies" element={<ComingSoonPage />} />
          <Route path="/updates" element={<ComingSoonPage />} />
          <Route path="/pricing" element={<ComingSoonPage />} />
          <Route path="/careers" element={<ComingSoonPage />} />
          <Route path="/partners" element={<ComingSoonPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/cookie-policy" element={<CookiePolicyPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faqs" element={<FAQPage />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;