import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Key, FileCheck } from 'lucide-react';
import { Link } from 'react-router-dom';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="flex items-start mb-6">
    <div className="mr-4 flex-shrink-0">
      <Icon className="w-6 h-6 text-[#0068d7]" />
    </div>
    <div>
      <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const SecurityShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-green-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left side: Text content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 lg:pr-16 mb-10 lg:mb-0"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Uncompromising Security</h2>
            <p className="text-xl mb-8 text-gray-700">
              At Argus, we prioritize the security and privacy of your data. Our robust security measures ensure that your information remains protected and compliant with industry standards.
            </p>
            <ul className="space-y-4 mb-8">
              <li className="flex items-start text-gray-700">
                <Shield className="w-5 h-5 mr-2 text-[#0068d7] mt-1 flex-shrink-0" />
                <span>Multi-layered security approach for comprehensive protection</span>
              </li>
              <li className="flex items-start text-gray-700">
                <Lock className="w-5 h-5 mr-2 text-[#0068d7] mt-1 flex-shrink-0" />
                <span>Regular security audits and penetration testing</span>
              </li>
              <li className="flex items-start text-gray-700">
                <Key className="w-5 h-5 mr-2 text-[#0068d7] mt-1 flex-shrink-0" />
                <span>Granular access controls and user permissions</span>
              </li>
            </ul>
            <Link to='/features/enterprise-security'>
              <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300">
                Learn More About Security
              </button>
            </Link>
          </motion.div>

          {/* Right side: Security features */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2"
          >
            <div className="bg-white rounded-lg shadow-xl p-8">
              <SecurityFeature
                icon={Shield}
                title="Enterprise-Grade Security"
                description="Argus employs state-of-the-art security measures to protect your data at all times."
              />
              <SecurityFeature
                icon={Lock}
                title="AES 256 Encryption at Rest"
                description="All data stored in Argus is encrypted using AES 256-bit encryption, ensuring maximum protection."
              />
              <SecurityFeature
                icon={Key}
                title="TLS 1.2+ Encryption in Transit"
                description="Data in transit is secured using TLS 1.2 or higher, preventing unauthorized access during transfer."
              />
              <SecurityFeature
                icon={FileCheck}
                title="Compliance Ready"
                description="Argus is designed to help you meet various compliance requirements, including GDPR, HIPAA, and more."
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default SecurityShowcase;