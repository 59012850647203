import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Search, Tag } from 'lucide-react';
import { Link } from 'react-router-dom';

const DocumentManagementShowcase = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-green-100 to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row-reverse items-center">
          {/* Left side: Mock-up */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full lg:w-1/2 mb-10 lg:mb-0"
          >
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="bg-gray-200 px-4 py-2 flex items-center justify-between">
                <span className="text-sm text-gray-700 font-medium">Document Management</span>
              </div>
              <div className="p-6">
                <div className="mb-4">
                  <div className="flex items-center mb-2">
                    <Search className="w-5 h-5 text-gray-500 mr-2" />
                    <input type="text" placeholder="Search documents..." className="w-full p-2 border rounded" />
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <Tag className="w-4 h-4 text-blue-500" />
                    <span className="text-sm text-blue-500">Contract</span>
                    <Tag className="w-4 h-4 text-green-500" />
                    <span className="text-sm text-green-500">Proposal</span>
                    <Tag className="w-4 h-4 text-red-500" />
                    <span className="text-sm text-red-500">Legal</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center p-2 bg-gray-100 rounded">
                    <FileText className="w-5 h-5 text-blue-500 mr-2" />
                    <span className="text-sm text-gray-700">Q4 Financial Report.pdf</span>
                  </div>
                  <div className="flex items-center p-2 bg-gray-100 rounded">
                    <FileText className="w-5 h-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-700">Project Proposal - Client X.docx</span>
                  </div>
                  <div className="flex items-center p-2 bg-gray-100 rounded">
                    <FileText className="w-5 h-5 text-red-500 mr-2" />
                    <span className="text-sm text-gray-700">NDA Template.pdf</span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right side: Text content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full lg:w-1/2 lg:pr-16"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Effortless Document Management</h2>
            <p className="text-xl mb-8 text-gray-700">
              Organize and manage large volumes of documents with ease. Argus helps you end the chaos of scattered data, providing a centralized hub for all your important files.
            </p>
            <ul className="space-y-2 mb-8">
              <li className="flex items-center text-gray-700">
                <Search className="w-5 h-5 mr-2 text-green-500" />
                Fast, accurate search across all documents
              </li>
              <li className="flex items-center text-gray-700">
                <Tag className="w-5 h-5 mr-2 text-green-500" />
                Intelligent tagging and categorization
              </li>
              <li className="flex items-center text-gray-700">
                <FileText className="w-5 h-5 mr-2 text-green-500" />
                Version control and collaboration features
              </li>
            </ul>
            <Link to='features/document-management'>
              <button className="px-8 py-3 bg-[#0068d7] text-white rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300">
                Explore Document Management
              </button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default DocumentManagementShowcase;