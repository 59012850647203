import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Shield, Cog, BarChart, Globe, Key, AlertCircle, Settings, HelpCircle } from 'lucide-react';
import './CookiePolicyPage.css';

const lastUpdated = "November 1, 2024";

const sections = [
    {
        icon: Cookie,
        title: "What Are Cookies",
        content: `Cookies are small text files that are stored on your device when you visit our website. They help make your browsing experience better by:

• Remembering your preferences and settings
• Helping you navigate between pages efficiently
• Enabling certain features and functionalities
• Analyzing how you interact with our site

Cookies can be temporary ("session cookies") or permanent ("persistent cookies"). Session cookies are deleted when you close your browser, while persistent cookies remain on your device for a set period.`
    },
    {
        icon: Shield,
        title: "How We Use Cookies",
        content: `We use cookies for the following purposes:

Essential Cookies:
• User authentication and security
• Shopping cart functionality
• Basic site operations
• Session management

Preference Cookies:
• Language settings
• Theme preferences
• User interface customization
• Regional settings

Analytics Cookies:
• Understanding user behavior
• Improving site performance
• Analyzing feature usage
• Tracking error rates

Marketing Cookies:
• Personalized content delivery
• Advertisement optimization
• Campaign effectiveness tracking
• Cross-site marketing`
    },
    {
        icon: Cog,
        title: "Types of Cookies We Use",
        content: `Our website uses the following categories of cookies:

1. Strictly Necessary Cookies:
• Required for core site functionality
• Cannot be disabled
• Essential for security features
• Needed for basic operations

2. Performance Cookies:
• Track site usage and performance
• Help us improve user experience
• Measure server response times
• Monitor site stability

3. Functional Cookies:
• Remember your preferences
• Customize your experience
• Store user choices
• Enable social sharing features

4. Targeting/Advertising Cookies:
• Show relevant advertisements
• Measure campaign effectiveness
• Track marketing interactions
• Enable retargeting features`
    },
    {
        icon: BarChart,
        title: "Third-Party Cookies",
        content: `We work with trusted partners who may also place cookies on your device:

Analytics Partners:
• Google Analytics
• Mixpanel
• Heap Analytics

Marketing Partners:
• Google Ads
• LinkedIn Insights
• Facebook Pixel

These third-party cookies are subject to their respective privacy policies, and we recommend reviewing their policies for more information.

We regularly review our partnerships to ensure compliance with privacy standards.`
    },
    {
        icon: Key,
        title: "Cookie Consent",
        content: `We obtain your consent for non-essential cookies through our cookie banner when you first visit our site.

Your Rights:
• Accept or reject non-essential cookies
• Change preferences at any time
• Withdraw consent easily
• Access detailed cookie information

Cookie preferences can be updated through our cookie settings panel, accessible via the footer of our website.`
    },
    {
        icon: Settings,
        title: "Managing Cookies",
        content: `You can control cookies through several methods:

Browser Settings:
• Clear existing cookies
• Block new cookies
• Enable/disable specific cookies
• Set cookie preferences

Our Cookie Settings:
• Access via cookie banner
• Granular control options
• Preference management
• Easy opt-out features

Please note that blocking some types of cookies may impact your experience on our website.`
    },
    {
        icon: Globe,
        title: "International Data Transfers",
        content: `Cookies may result in your data being transferred internationally:

• Data may be processed outside your country
• We ensure appropriate safeguards are in place
• Transfers comply with applicable data protection laws
• We maintain security standards across all regions

We only transfer data to countries with adequate data protection standards or through appropriate safeguards like Standard Contractual Clauses.`
    },
    {
        icon: AlertCircle,
        title: "Updates to This Policy",
        content: `We may update this Cookie Policy from time to time to reflect:

• Changes in our practices
• New regulatory requirements
• Technology advancements
• User feedback and concerns

Changes will be effective immediately upon posting the updated policy on our website.

We encourage you to review this policy periodically to stay informed about our use of cookies.`
    },
];

const CookiePolicyPage = () => {
    return (
        <div className="cookie-policy-page">
            {/* Hero Section */}
            <motion.div 
                className="cookie-policy-hero"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    Cookie Policy
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    Learn about how we use cookies to improve your experience.
                </motion.p>
                <motion.div
                    className="last-updated"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    Last Updated: {lastUpdated}
                </motion.div>
            </motion.div>

            {/* Cookie Banner Button Example */}
            <motion.div 
                className="cookie-settings-button"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
            >
                <button className="settings-button" onClick={() => window.openCookieSettings?.()}>
                    <Settings size={16} />
                    Manage Cookie Preferences
                </button>
            </motion.div>

            {/* Quick Nav */}
            <div className="quick-nav">
                <div className="container">
                    <div className="quick-nav-content">
                        {sections.map((section, index) => (
                            <a 
                                key={index} 
                                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                                className="quick-nav-item"
                            >
                                {section.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            {/* Content Sections */}
            <div className="cookie-policy-content">
                <div className="container">
                    {sections.map((section, index) => (
                        <motion.section
                            key={index}
                            id={section.title.toLowerCase().replace(/\s+/g, '-')}
                            className="cookie-policy-section"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.1 }}
                        >
                            <div className="section-header">
                                <section.icon className="section-icon" />
                                <h2>{section.title}</h2>
                            </div>
                            <div className="section-content">
                                {section.content.split('\n\n').map((paragraph, i) => (
                                    <p key={i}>{paragraph}</p>
                                ))}
                            </div>
                        </motion.section>
                    ))}
                </div>
            </div>

            {/* Contact Section */}
            <div className="cookie-policy-contact">
                <div className="container">
                    <motion.div
                        className="contact-card"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <h2>Questions About Cookies?</h2>
                        <p>Our privacy team can help you understand how we use cookies to improve your experience.</p>
                        <a href="/contact" className="contact-button">
                            Contact Privacy Team
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicyPage;